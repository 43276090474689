import * as React from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Accesibilidad from "../components/accesibilidad"

let url_global = process.env.GATSBY_API_URL
let image_url =
  "url(" + url_global + "/uploads/" + "valla_03_6e3f99d9a6.jpg" + ")"
const ErrorPage = ({ site, siteTitle }) => (
  <>
    <Accesibilidad />
    <Header site="personas" />
    <Seo title="Personas" />

    <div
      className="absolute w-screen altura_banner_blog ancho_banner_fondo_blog_sm altura_banner_quienes_somos lg:w-screen altura_banner_quienes_somos posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
      title="valla_03.jpg"
      style={{
        backgroundImage: `${image_url}`,
      }}
    ></div>
    <div
      className="container mx-auto titulo_principal_blog_sm titulo_blog_encabezado_alinear text-poppins-bold lg:relative titulo_ventaja_lg"
      style={{
        width: "95%",
        left: "4rem",
        position: "relative",
        color: "#256238",
      }}
    >
      <p>
        Error <span className="green-secundary">desconocido</span>
      </p>
    </div>

    <div
        className="container mx-auto"
        style={{ marginTop: "19rem"}}
      >
</div>        

    <Footer />
  </>
)

ErrorPage.propTypes = {
  siteTitle: PropTypes.string,
}

ErrorPage.defaultProps = {
  siteTitle: ``,
}

export default ErrorPage
 
